import React from 'react';
import { Typography } from '@mui/material';

// Status styles mapping
const statusStyles = {
  CONFIRMED: {
    color: "#FF7A00",
    border: "1px solid #FF7A00",
  },
  PENDING: {
    color: "#E2A700",
    border: "1px solid #E2A700",
  },
  REJECTED: {
    color: "#D32F2F",
    border: "1px solid #D32F2F",
  },
  COMPLETED: {
    color: "#388E3C",
    border: "1px solid #388E3C",
  },
  CANCELED: {
    color: "#9E9E9E",
    border: "1px solid #9E9E9E",
  },
  // Add more cases if necessary
};

// Reusable StatusTypography component
const StatusTypography = ({ status }) => {
  const currentStatusStyle = statusStyles[status] || {
    color: "#000000", // Default color if status is not found
    border: "1px solid #000000", // Default border
  };

  return (
    <Typography
      variant="body2"
      sx={{
        ...currentStatusStyle,
        borderRadius: "4px",
        padding: "2px 8px",
        fontWeight: "bold",
        fontSize: "0.875rem",
      }}
    >
      {status}
    </Typography>
  );
};

export default StatusTypography;
