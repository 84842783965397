import React, { useEffect, useState } from "react";
import VendorCard from "./VenderCard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../utils/authUtils";
import { VenderDetailsAPI } from "../../api/VenderDetails/VenderDetailsAPI";
import { fetchFavoriteVendors } from "../../features/Vendors/favoriteVendorsSlice";
import { StyledBox, StyledOrderBox } from "../../components/UI/StyleBox";
import { Box, Container, Grid, Typography } from "@mui/material";
import { BackButton } from "../../components/UI/Button";
// import { StyledOrderBox } from '../Order/BroadCastDetail';
import Loader from "../../components/comman/Loader";
import TopVenderCard from "./TopVenderCard";

const TopVenderList = () => {
  const location = useLocation();
  const { filterDistance } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { user_id } = getUser();
  const [data2, setData2] = useState([]);
  const FetchTopVenderList = async () => {
    setIsLoading(true);
    let data = {
      user_id: user_id,
      km: filterDistance,
    };
    try {
      const response = await VenderDetailsAPI.TopVendors(data); // Await the API call
      const ResponseData = response.data;
      console.log(ResponseData, "ResponseData");
      if (ResponseData.status === 200) {
        setData2(ResponseData.vendors_list);
      }
    } catch (error) {
      console.error("Error fetching vendor list:", error); // Handle errors
    } finally {
      setIsLoading(false); // Ensure loading state is updated
    }
  };
  useEffect(() => {
    FetchTopVenderList();
  }, [dispatch, user_id]);

  if (isLoading) return <Loader />;
  return (
    <>
      <StyledBox>
        <Box>
          <Typography variant="h5" sx={{ color: "#FFFFFF", fontWeight: 600 }}>
            {"Top Vendors Within " + filterDistance + " Kms."}
          </Typography>
        </Box>
        <Box>
          <BackButton />
        </Box>
      </StyledBox>
      <StyledOrderBox>
        <Container>
          <Grid container spacing={3}>
            {data2.length > 0 ? (
              data2.map((vendor) => (
                <Grid item xs={12} md={6} key={vendor.user_id}>
                  <TopVenderCard vendor={vendor} user_id={user_id} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginTop: 4,
                    padding: 2,
                    color: "gray",
                  }}
                >
                  Data Not Found!
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </StyledOrderBox>
    </>
  );
};

export default TopVenderList;
