import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { endpoints } from "../../config/config";

export const fetchOrdersList = createAsyncThunk(
  "orders/fetchOrdersList",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.my_order, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOrdersHistory = createAsyncThunk(
  "orders/fetchOrdersHistory",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endpoints.order_history, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
  "orders/fetchOrderDetails",
  async (userData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        endpoints.order_details,
        userData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    userOrders: [],
    orderDetails: {},
    orderHistory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {
        state.loading = false;
        state.userOrders = action.payload;
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOrderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.orderDetails = action.payload.order_list;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })  
      .addCase(fetchOrdersHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdersHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.orderHistory = action.payload.order_history_list;
      })
      .addCase(fetchOrdersHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default ordersSlice.reducer;
