import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateMobileEmail,
  verifyUpdateRequestOtp,
} from "../../features/Profile/userSlice";
import { getUser } from "../../utils/authUtils";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import OtpModal from "../Auth/OtpModal";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { viewProfile } from "../../features/Profile/profileSlice";

const EditableField = ({ label, defaultValue, isMobile, userValue ,fromEditForm}) => {
  const { user_id } = getUser();
  const dispatch = useDispatch();
  const [isChangeEnabled, setChangeEnabled] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);


  const handleEnableChange = () => {
    setChangeEnabled(!isChangeEnabled);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: user_id,
      mobile: isMobile ? userValue ?? "" : "",
      email: !isMobile ? userValue ?? "" : "",
    },
    validationSchema: Yup.object().shape({
      ...(isMobile
        ? {
            mobile: Yup.string()
              .required("Mobile Number is required")
              .matches(/^[0-9]{10}$/, "Mobile number is invalid"),
          }
        : {
            email: Yup.string()
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Invalid email address"
              )
              .required("Email address is Required"),
          }),
    }),
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        let { payload } = await dispatch(updateMobileEmail(values));
        if (payload.status === 200) {
          handleOpen();
        } else {
          notifyError(payload.msg);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [timer, setTimer] = useState(30);
  const handleOpen = () => {
    setOtpModalOpen(true);
    setTimer(30);
  };
  const handleCloseOtpModal = () => {
    setOtpModalOpen(false);
  };
  const handleVerifyOTPSuccess = () => {
    setChangeEnabled(false);
    setHasChanged(false);
    dispatch(viewProfile({ user_id: user_id }));
  };
  const handleUpdateInfo = async () => {
    if (formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.setTouched({
        mobile: true,
        email: true,
      });
    }
  };

  useEffect(() => {
    if (
      (isMobile && formik.values.mobile !== (userValue ?? "")) ||
      (!isMobile && formik.values.email !== (userValue ?? ""))
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [formik.values, isMobile, userValue]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: ".7rem",
              textAlign: "end",
              cursor: "pointer",
            }}
            onClick={handleEnableChange}
            color={"primary"}
          >
            Change {label}
          </Typography>
          <TextField
            fullWidth
            label={label}
            variant="outlined"
            name={isMobile ? "mobile" : "email"}
            sx={{ mt: 1 }}
            disabled={!isChangeEnabled}
            value={isMobile ? formik.values.mobile : formik.values.email}
            onChange={formik.handleChange}
            error={
              (isMobile &&
                formik.touched.mobile &&
                Boolean(formik.errors.mobile)) ||
              (!isMobile &&
                formik.touched.email &&
                Boolean(formik.errors.email))
            }
            helperText={
              (isMobile && formik.touched.mobile && formik.errors.mobile) ||
              (!isMobile && formik.touched.email && formik.errors.email)
            }
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {hasChanged ? (
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={handleUpdateInfo}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        <SaveIcon />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton edge="end" color="success">
                      <CheckIcon size={24} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: isMobile && 10,
            }}
          />
        </Box>
        {/* <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={!isChangeEnabled || isSubmitting}
          sx={{ ml: 2, mt: 3 }}
          onClick={handleUpdateInfo}
        >
          {isSubmitting ? "Save..." : " Save"}
        </Button> */}
      </Box>
      <OtpModal
        open={otpModalOpen}
        handleClose={handleCloseOtpModal}
        timer={timer}
        setTimer={setTimer}
        handleResendOTP={handleUpdateInfo}
        updateUser={true}
        handleVerifyOTPSuccess={handleVerifyOTPSuccess}
        fromEditForm1={fromEditForm}
      />
    </>
  );
};

export default EditableField;
