import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { parseISO } from "date-fns";

export const formatDateTime = (dateString) => {
  const dateTime = new Date(dateString);
  const formattedDate = dateTime.toLocaleDateString("en-GB");
  const formattedTime = dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { formattedDate, formattedTime };
};

export const formatDateTimeForBroadcast = (dateString, timeString) => {
  const dateTime = new Date(`${dateString}T${timeString}`);
  const formattedDate = dateTime.toLocaleDateString("en-GB");
  const formattedTime = dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { formattedDate, formattedTime };
};


export const formatChatTime = (dateString) => {
  return new Date(dateString).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formateDate = (dateString) => {
  try {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  } catch (error) {
    return "Invalid";
  }
};

export const timeAgo = (dateString) => {
  const date = new Date(dateString);
  const formattedDistance = formatDistanceToNow(date, {
    addSuffix: true,
    includeSeconds: true,
  });
  return `${formattedDistance}`;
};

export const changeFormatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const formatTime = (timeString) => {
  const [hours, minute] = timeString.split(":");
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${String(formattedHour).padStart(2, "0")}:${minute} ${ampm}`;
};
