import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  Box,
  Tooltip,
  Avatar,
  IconButton,
} from "@mui/material";
import EditProfileIcon from "../../assets/images/edit-info.png";
import BroadcastJobIcon from "../../assets/images/recent_order.png";
import JobHistoryIcon from "../../assets/images/history.png";
import TermsOfUseIcon from "../../assets/images/terms-conditions.png";
import PrivacyPolicyIcon from "../../assets/images/privacy-policy.png";
import DeleteAccountIcon from "../../assets/images/deleteAcc.png";
import { ArrowDropDown, ArrowDropUp, LocationOn } from "@mui/icons-material";
import { getUser } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteAccountModal from "../comman/DeleteAccountModal";
import { deleteAccount } from "../../features/Profile/userSlice";
import { notifyError, notifySuccess } from "../../utils/notificationService";
import { logout } from "../../features/auth/authSlice";

const settings = [
  { text: "Edit Profile", icon: EditProfileIcon, path: "/edit-profile" },
  { text: "My Broadcast List", icon: BroadcastJobIcon, path: "/broadcast-list" },
  { text: "Job History", icon: JobHistoryIcon, path: "/job-history" },
  { text: "Terms of Use", icon: TermsOfUseIcon, path: "/terms-of-use" },
  { text: "Privacy Policy", icon: PrivacyPolicyIcon, path: "/privacy-policy" },
  { text: "Delete Account", icon: DeleteAccountIcon, path: "delete-account" }, 
];


const UserMenu = () => {
  const { userProfile, loading } = useSelector((state) => state.profile);
  const {
    user_id,
    username,
    name,
    description,
    profile_image,
    image_1,
    image_2,
    image_3,
    mobile,
    email,
    address,
    lat,
    long,
  } = userProfile ?? {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handelClose = () => setOpen(false);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setUserMenuOpen((prev) => !prev);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setUserMenuOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = async () => {
    const { payload } = await dispatch(deleteAccount(user_id));
    if (payload.status === 200) {
      dispatch(logout());
      // navigate("/login");
      window.location.href="/login"
      notifySuccess(payload.msg);
    } else {
      notifyError(payload.msg);
    }
    handleClose();
  };
  const handleMenuItemClick = (page) => {
    if (page.path === "delete-account") {
      handleOpen();
    } else {
      navigate(page.path);
    }
    handleCloseUserMenu();
  };

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!loading && (
            <>
              <Avatar alt={name} src={profile_image} />
              <Typography variant="body1" sx={{ mx: 2 }}>
                {name?.slice(0, 8)}
              </Typography>
            </>
          )}

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {userMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(setting)}>
              <ListItemIcon>
                <img
                  src={setting.icon}
                  alt={setting.text}
                  style={{ width: 24, height: 24, marginRight: 8 }}
                />
              </ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  textAlign: "left",
                }}
              >
                {setting.text}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <DeleteAccountModal
        open={modalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default UserMenu;
