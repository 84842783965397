import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Rating, Typography, CircularProgress } from '@mui/material';

const RatingDialog = ({ open, onClose, onSubmit, serviceName ,isLoading}) => {
  const [rating, setRating] = useState(0);
  const [isRated, setIsRated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    if (newValue > 0) {
      setIsRated(true); // Update state when a rating is given
    }
  };

  const handleSubmit = () => {
      onSubmit(rating);
      // onClose();

  };

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="sm" fullWidth disableBackdropClick>
      <DialogTitle>{isRated ? 'Thank you for your rating' : 'Your opinion matters to us!'}</DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Rating
          name="half-rating"
          value={rating}
          precision={0.5}
          onChange={handleRatingChange}
          size="large"
          sx={{
            fontSize: 60, // Increased star size
            '& .MuiRating-iconFilled': {
              marginTop: '5px', // Apply margin to filled stars (whole and half)
            },
            '& .MuiRating-iconHalf': {
              marginTop: '5px', // Apply margin to half stars only
            },
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={rating === 0}
          variant="contained"
          style={{ 
            position: 'relative', 
            borderRadius: '50px', // Make the button oval
            padding: '10px 20px', // Add some padding for better shape
            minWidth: '150px', // Ensure the button has a minimum width
          }} 
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
