import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD_s6tzwx9LcWyM6I5RKTUqhP2M4RTvL8w",
  authDomain: "suluhu-new.firebaseapp.com",
  projectId: "suluhu-new",
  storageBucket: "suluhu-new.appspot.com",
  messagingSenderId: "776466010518",
  appId: "1:776466010518:web:54a82033fe23ea1aff0db7",
  measurementId: "G-2QVRLQB8RR",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc };
