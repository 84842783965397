import React, { useEffect, useState } from "react";
import { StyledBox, StyledIconButton } from "../../components/UI/StyleBox"; 
import {
  Avatar,
  Box,
  Card,
  Dialog,
  DialogContent,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import { BackButton } from "../../components/UI/Button";
import { motion } from "framer-motion";
import { fetchBroadcastResponse } from "../../features/Broadcast/broadcastListSlice";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "../../components/comman/withRouter";
import { OrderCard } from "./MyOrderList";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Loader from "../../components/comman/Loader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import ChatIcon from "@mui/icons-material/Chat";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { changeFormatDate, formatTime } from "../../utils/dateUtils";
import { getUser } from "../../utils/authUtils";
import { BiSolidEdit } from "react-icons/bi";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { CatagoryListAPI } from "../../api/JobBroadcast/CatagoryListAPI";
import { notifySuccess, notifyError } from "../../utils/notificationService";
import deleteIcon from "../../assets/images/deleteIcon.png";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
export const StyledOrderBox = styled(Box)({
  padding: "20px",
  background: "#ffffff",
  width: "100%",
  margin: "auto",
  maxWidth: "850px",
  zIndex: 10,
  marginTop: "-100px",
  borderRadius: "1rem",
  borderBottom: "3px solid #1976d2",
});

import MapHelper from "../PostBroadCast/MapHelper";
import { RiMapPinFill } from "react-icons/ri";
import CustomButton1 from "../../components/comman/CustomButton1";
import { blue } from "@mui/material/colors";
import StatusTypography from "../../components/comman/StatusTypography";
import ApproveService from "./ApproveService";
import StarIcon from "@mui/icons-material/Star";
const BroadCastDetail = ({ router }) => {
  const theme = useTheme();

  const [JobDetails, setJobDetails] = useState();
  const [finalCoordinates, setFinalCoordinates] = useState(null);
  const [address, setAddress] = useState(null);
  const [firstPart, setFirstPart] = useState(true);
  const [dateModal, setDateModal] = useState(false);
  const [demoFlag, setDemoFlag] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [jobDate, setJobDate] = useState(""); 
  const [jobTime, setJobTime] = useState("");
  const [amount, setAmount] = useState("");
  const [CustomDisable, setCustomDisable] = useState("button2");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (jobDetails.status === "CONFIRMED") {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, []);

  const { user_id } = getUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    params: { broadcast_id },
  } = router;

  const { broadcastDetail, loading, error } = useSelector(
    (state) => state.broadcastList
  );
  const { broadcast_response, job_detail } = broadcastDetail ?? {};

 

  useEffect(() => {
    dispatch(fetchBroadcastResponse({ broadcast_id }));
  }, [dispatch, broadcast_id]);

  const HandleClick = (VenderData) => {
    let provider_id = VenderData.provider_id;
    let DisabledQuantity = false;
    navigate("/vender-details", {
      state: { provider_id, user_id, DisabledQuantity },
    });
  };

  const HandelClose = () => {
    setShowMap(false);
    setModal(false);
  };
  const handleClose = () => {
    setDeleteModal(false);
  };
  const HandelDelete = () => {
    setModal(false);
    setDeleteModal(true);
  };

  const HandleEdit = () => {
    setModal(true);
  };
  const toggle = () => {
    setModal(!modal);
  };
  const jobDetails = broadcastDetail?.job_detail[0] ?? {};

  const [modal, setModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    if (broadcast_response) {
      setJobDetails(jobDetails?.job_detail);
      setAmount(jobDetails?.job_budget);
      setJobDate(jobDetails?.job_dt);
      setJobTime(jobDetails?.job_tm);
      setFinalCoordinates({
        lat: jobDetails?.job_lat,
        lng: jobDetails?.job_long,
      });
      setAddress(jobDetails?.job_location);
    }
  }, [modal]);
  const handleMapButtonClick = () => {
    setShowMap(true);
  };

  const handleAmountChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters except decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Check if the input is a non-negative number with optional decimal places
    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      setAmount(inputValue); // Update the state only if the input is valid
    }
  };
  useEffect(() => {
    const isJobDetailsValid = JobDetails && JobDetails.trim() !== "";
    if (isJobDetailsValid && jobDate && jobTime && address && amount) {
      setCustomDisable("button1");
    } else {
      setCustomDisable("button2");
    }
  }, [JobDetails, jobDate, jobTime, address, amount]);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
  const yyyy = today.getFullYear();

  const todayDate = `${yyyy}-${mm}-${dd}`;

  // Get date 5 days from todays
  const futureDate = new Date(today);
  futureDate.setDate(futureDate.getDate() + 5);
  const futureDd = String(futureDate.getDate()).padStart(2, "0");
  const futureMm = String(futureDate.getMonth() + 1).padStart(2, "0"); // January is 0
  const futureYyyy = futureDate.getFullYear();
  const maxDate = `${futureYyyy}-${futureMm}-${futureDd}`;

  const HandleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      let data = {
        job_id: jobDetails?.broadcast_id,
        description: JobDetails,
        job_dt: jobDate,
        job_tm: jobTime,
        location: address,
        job_budget: amount,
        lat: finalCoordinates?.lat,
        long: finalCoordinates?.lng,
      };
      const response = await CatagoryListAPI.updateBroadcastJob(data);
      if (response.data.status === 200) {
        setModal(false);
        dispatch(fetchBroadcastResponse({ broadcast_id }));
        notifySuccess(response.data.msg);
      } else {
        notifyError(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let data = {
      broadcast_id: jobDetails?.broadcast_id,
      status: "CANCELLED",
    };

    console.log(jobDetails?.broadcast_id ,"jobDetails?.broadcast_id")

    try {
      const response = await CatagoryListAPI.changeJobStatus(data);
      if (response.data.status === 200) {
        notifySuccess(response.data.msg);
        navigate("/broadcast-list");
      } else {
        notifyError(response.data.msg);
      }
    } catch (err) {
      y;
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };


  // useEffect(() => {
  //   if (jobDetails?.job_status === "COMPLETED") {
  //     navigate(`/approve-service/${jobDetails.order_id}`, {
  //       state: { mode: 'Broadcast' } // replace with your actual state
  //     });
  //   }
  // }, [jobDetails?.job_status, jobDetails?.order_id, navigate]);


  if (loading && isLoading) return <Loader />;
  return (
    <>
   
     
          <StyledBox>
            <Box>
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: 600 }}
              >
                Broadcast Request
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%", // Adjust width as needed
                }}
              >
                {jobDetails.job_status !== "CONFIRMED" &&
                  jobDetails.job_status !== "CANCELLED" &&
                  jobDetails.job_status !== "COMPLETED" && (
                    <>
                      <IconButton>
                        <TiDeleteOutline
                          size={24}
                          style={{ color: "#FFFFFF", cursor: "pointer" }}
                          onClick={HandelDelete}
                        />
                      </IconButton>
                      <IconButton>
                        <BiSolidEdit
                          size={23}
                          style={{ color: "#FFFFFF", cursor: "pointer" }}
                          onClick={HandleEdit}
                        />
                      </IconButton>
                    </>
                  )}

                <BackButton />
              </Box>
            </Box>
          </StyledBox>
          <StyledOrderBox>
            <Container>
              {job_detail?.map((order) => (
                <React.Fragment>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography
                        variant="h6"
                        component="div"
                        color="black"
                        sx={{ fontWeight: "bold", fontSize: "1.7rem" }}
                      >
                        Job No: {order.broadcast_no}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <EventIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          //   sx={{ fontSize: "0.875rem" }}
                        >
                          {changeFormatDate(order.job_dt)}
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          //   sx={{ fontSize: "0.875rem" }}
                        >
                          {formatTime(order.job_tm)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" mt={1}>
                    <>
                      <img
                        src={order?.category?.category_image}
                        alt={order?.category?.category_name}
                        style={{ width: 28 }}
                      />
                      <Typography
                        variant="h6"
                        color="black"
                        sx={{ fontSize: "1.2rem", marginLeft: 1 }}
                      >
                        {order?.category?.category_name}
                      </Typography>
                    </>
                  </Box>
                  <Box display="flex" alignItems="center" ml={4}>
                    <>
                      <img
                        src={order?.sub_category?.sub_category_image}
                        alt={order?.sub_category?.sub_category_name}
                        style={{ width: 22 }}
                      />
                      <Typography
                        variant="h6"
                        color="secondary"
                        sx={{ fontSize: "0.9rem", margin: 1 }}
                      >
                        {order?.sub_category?.sub_category_name}
                      </Typography>
                    </>
                  </Box>

                  <p
                    style={{
                      fontWeight: "200",
                      color: "#817e7e",
                      fontSize: "16px",
                    }}
                  >
                    {job_detail[0].job_detail}
                  </p>

                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      padding: "8px",
                      width: "100%",
                      // Adjust as needed for layout // Light background for the address box
                    }}
                  >
                    <LocationOnIcon
                      sx={{
                        marginRight: "8px", // Space between icon and text
                        color: "#757575", // Icon color (can be customized)
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#817e7e",
                        textAlign: "start", // Text color
                      }}
                    >
                      {job_detail[0].job_location}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "orange",
                      borderStyle: "dashed",
                      my: 1,
                    }}
                  />
                  <Box display="flex" justifyContent={"space-between"}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      //   sx={{ fontSize: "0.875rem" }}
                    >
                      Service Budget: KES {order.job_budget}
                    </Typography>
                    <StatusTypography status={order.job_status} />
                  </Box>
                </React.Fragment>
              ))}
            </Container>
          </StyledOrderBox>
          <Container>
            {broadcast_response?.length > 0 && (
              <Box className="service-card">
                <Typography variant="h6" textAlign={"left"}>
                  Interested Service Providers
                </Typography>
              </Box>
            )}
            <Grid container spacing={3}>
              {broadcast_response?.length > 0 ? (
                broadcast_response?.map((resp, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box className="header">
                      <Avatar
                        className="avatar"
                        src={resp.profile_image}
                        alt="James Anderson"
                        sx={{ width: 100, height: 100 }}
                      />
                      <Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="h6"
                            style={{ cursor: "pointer" }}
                            onClick={() => HandleClick(resp)}
                          >
                            {resp.username}
                          </Typography>
                          <NavLink
                            to={`/chat/${resp.provider_id}`}
                            state={{ mode: "broadcast", jobDetail: job_detail }}
                          >
                            <StyledIconButton>
                              <ChatIcon fontSize="small" />
                            </StyledIconButton>{" "}
                          </NavLink>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
              <StarIcon sx={{ color: "#FFD700", fontSize: "large" }} />{" "}
              {/* Gold star color */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ ml: 0.5 }}
              >
                {/* {vendor.ratings.toFixed(1)} */}3.5
              </Typography>
            </Box>
                        <Box mt={1} sx={{ display: "flex" }}>
                          <AddRoadIcon color="primary" sx={{ mr: 1 }} />
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            textAlign={"left"}
                          >
                            {resp.distance} Km
                          </Typography>
                        </Box>
                        <Box mt={1} sx={{ display: "flex" }}>
                          <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            textAlign={"left"}
                          >
                            {resp.address}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      marginTop: 4,
                      padding: 2,
                      color: "gray",
                      marginBottom: 5,
                    }}
                  >
                    No interested service provider yet.
                  </Typography>{" "}
                </Grid>
              )}
            </Grid>{" "}
          </Container>

          <Modal
            isOpen={modal}
            toggle={toggle}
            centered={true}
            zIndex={9999}
            size="lg"
            backdrop="static"
            className="custom-modal"
          >
            <ModalHeader
              className="d-flex justify-content-between align-items-center"
              toggle={HandelClose}
            >
              <div
                className="d-flex align-items-center "
                style={{ marginLeft: "40px" }}
              >
                <img
                  src={jobDetails?.category?.category_image}
                  alt="Suluhu Logo"
                  height="40"
                  style={{ height: "auto", width: "40px" }}
                  className="mr-2"
                />
                <div>
                  <h5
                    className="mb-0 d-flex flex-column "
                    style={{ marginLeft: "18px" }}
                  >
                    <span>{jobDetails?.category?.category_name}</span>
                    <div className="row">
                      <div className="col-1">
                        <span>
                          {" "}
                          <img
                            src={jobDetails?.sub_category?.sub_category_image}
                            height={20}
                            width={20}
                          />
                        </span>
                      </div>
                      <div className="col-10">
                        <span
                          style={{
                            color: "#0079EA",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        >
                          {jobDetails?.sub_category?.sub_category_name}
                        </span>
                      </div>
                    </div>
                  </h5>
                </div>
              </div>
            </ModalHeader>

            <ModalBody>
              <div className="container">
                {showMap ? (
                  <div className="map-container">
                    <MapHelper
                      setFinalCoordinates={setFinalCoordinates}
                      setShowMap={setShowMap}
                      setAddress={setAddress}
                      setFirstPart={setFirstPart}
                      setDateModal={setDateModal}
                      setDemoFlag={setDemoFlag}
                      setSelectedAddress={setSelectedAddress}
                    />
                  </div>
                ) : (
                  <div className="container">
                    <form style={{ fontWeight: "500" }}>
                      <div className="mb-3">
                        <label htmlFor="jobDetails">
                          Write us about your job requirement
                        </label>
                        <textarea
                          className="form-control "
                          style={{ color: "#868686" }}
                          placeholder="About your job details"
                          id="jobDetails"
                          rows="3"
                          value={JobDetails}
                          onChange={(e) => setJobDetails(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <div className="form-group">
                          <label htmlFor="jobLocation">
                            Select your job location
                          </label>
                        </div>
                        {!finalCoordinates && (
                          <button
                            type="button"
                            className="form-control"
                            style={{
                              backgroundColor: "#E8F3FD",
                              color: "white",
                            }}
                            onClick={handleMapButtonClick}
                          >
                            <span style={{ color: "#0079EA" }}>
                              <RiMapPinFill /> Map Location
                            </span>
                          </button>
                        )}
                        {address && (
                          <div
                            className="col-12"
                            style={{ position: "relative" }}
                          >
                            <input
                              className="form-control"
                              value={address}
                              style={{
                                paddingRight: "30px",
                                color: "#868686",
                              }}
                              readOnly
                            />
                            <RiMapPinFill
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                                pointerEvents: "auto",
                                color: "#ff6363",
                              }}
                              onClick={handleMapButtonClick}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <div className="row">
                          {" "}
                          <div className="col-6">
                            <label htmlFor="jobDate">Job date</label>
                            <input
                              type="date"
                              className="form-control"
                              style={{ color: "#868686" }}
                              id="jobDate"
                              value={jobDate}
                              min={todayDate}
                              max={maxDate}
                              onChange={(e) => setJobDate(e.target.value)}
                            />
                          </div>
                          <div className="col-6">
                            <label htmlFor="jobTime">Job time</label>
                            <input
                              type="time"
                              style={{ color: "#868686" }}
                              className="form-control"
                              id="jobTime"
                              value={jobTime}
                              onChange={(e) => setJobTime(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <hr className=""></hr>
                      <div className="container">
                        <div className="row d-flex">
                          <div className="col-lg-8 d-flex flex-wrap align-items-center">
                            <div className="p-1">
                              <label
                                htmlFor="amount"
                                style={{
                                  fontSize: "18px",
                                  lineHeight: "16px",
                                }}
                              >
                                Enter Amount
                              </label>
                            </div>
                            <div className="p-1 ">
                              <input
                                type="number"
                                className="form-control no-arrows"
                                id="amount"
                                style={{
                                  fontSize: "24px",
                                  width: "130px",
                                  height: "50px",
                                  fontWeight: "600",
                                  lineHeight: "48px",
                                  textAlign: "right",
                                }}
                                value={amount}
                                onChange={handleAmountChange}
                              />
                            </div>
                            <div className="p-1">
                              <label
                                htmlFor="amount"
                                style={{
                                  fontSize: "18px",
                                  lineHeight: "16px",
                                }}
                              >
                                (KES)
                              </label>
                            </div>
                          </div>
                          <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                            <button
                              className={CustomDisable}
                              disabled={CustomDisable === "button2"}
                              onClick={(e) => HandleSubmit(e)}
                            >
                              <span>Update</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>

          <Dialog
            open={DeleteModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            PaperProps={{
              component: motion.div,
              initial: { opacity: 0, scale: 0.75 },
              animate: { opacity: 1, scale: 1 },
              exit: { opacity: 0, scale: 0.75 },
              transition: { duration: 0.5 },
              sx: {
                borderRadius: "1rem",
                maxWidth: "450px",
                mx: "auto",
                p: 2,
                textAlign: "center",
                position: "relative",
                overflow: "visible",
              },
            }}
          >
            <DialogContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                  boxShadow: 3,
                  width: "6rem",
                  height: "6rem",
                  margin: "0 auto 1rem auto",
                  position: "absolute",
                  top: "-4rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={deleteIcon}
                  alt="Suluhu Logo"
                  style={{ width: "5rem" }}
                />
              </Box>
              <Typography variant="h6" gutterBottom></Typography>
              <Typography
                variant="body2"
                sx={{ mb: 3, color: theme.palette.secondary.main }}
              >
                Are you sure you want to Cancel this job?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  variant="outlined"
                  style={{ borderRadius: "20px", minWidth: "100px" }}
                  onClick={() => handleClose()}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "20px",
                    minWidth: "100px",
                    backgroundColor: "#0079EA",
                  }}
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </>
   
  );
};

export default withRouter(BroadCastDetail);

// {
//     "provider_id": 103,
//     "username": "vedant kothekar",
//     "mobile": "8530926268",
//     "email": "vedantkothekar85@gmail.com",
//     "address": "16, Lokhande Nagar, Subhash Nagar, Trimurtee Nagar, Nagpur, Maharashtra 440022, India",
//     "lat": 21.1175788,
//     "long": 79.0437484,
//     "distance": 4.2,
//     "description": "",
//     "profile_image": ""
// }
